import { Provider } from "styletron-react";
import {
  Client as StyletronClient,
  Server as StyletronServer,
} from "styletron-engine-atomic";
const getServerStylesElements = () => {
  return document.querySelectorAll(
    "style._styletron_hydrate_"
  ) as NodeListOf<HTMLStyleElement>;
};
export const StyletronProvider = Provider;
export const styletron =
  typeof window === "undefined"
    ? new StyletronServer()
    : new StyletronClient({
        // see: _document.tsx for ssr styles
        hydrate: getServerStylesElements(),
      });
