import type { AppProps } from "next/app";
import { AppProviders } from "@/app/providers";
import { BlankLayout } from "@/app/layout";
import "@/app/styles/global-styles.css";
import "keen-slider/keen-slider.min.css";
import React from "react";
import NProgress from "nprogress";
import "../public/assets/nprogress.css";
import { Router } from "next/router";

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AppLayout = (Component as any).Layout || BlankLayout;
  React.useEffect(() => {
    Router.events.on("routeChangeStart", () => NProgress.start());
    Router.events.on("routeChangeComplete", () => NProgress.done());
    Router.events.on("routeChangeError", () => NProgress.done());
  }, []);
  return (
    <AppProviders>
      <AppLayout pageProps={pageProps}>
        <Component {...pageProps} />
      </AppLayout>
    </AppProviders>
  );
};
export default MyApp;
