import type { Theme } from "baseui";
import type { ThemePrimitives } from "baseui/theme";
import { BaseProvider, createTheme, lightThemePrimitives } from "baseui";

type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>;

const primaryFontFamily: string = [
  "Montserrat",
  "ui-sans-serif",
  "system-ui",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  '"Noto Sans"',
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  '"Noto Color Emoji"',
].join(",");
const primitives: Partial<ThemePrimitives> = {
  ...lightThemePrimitives,
  primaryFontFamily,
};
const overrides: DeepPartial<Theme> = {
  borders: {
    buttonBorderRadius: "4px",
  },
  colors: {
    buttonPrimaryFill: "#303030",
    buttonPrimaryHover: "#404040",
    buttonPrimaryText: "#FFFFFF",
    buttonSecondaryFill: "#F7F7F7",
    buttonSecondaryHover: "#E7E7E7",
    buttonSecondaryText: "#303030",
  },
};
export const theme = createTheme(primitives, overrides);
export const BaseWebThemeProvider = BaseProvider;
