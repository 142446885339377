import type { ComponentType } from "react";

import NewYorkIcon from "../assets/newyork.svg";
import LosAngelesIcon from "../assets/losangeles.svg";
import ChicagoIcon from "../assets/chicago.svg";
import DallasIcon from "../assets/dallas.svg";
import MiamiIcon from "../assets/miami.svg";

type CityItem = {
  icon: ComponentType;
  title: string;
};
// todo: use assets instead of embedding svg into bundle
export const cities: CityItem[] = [
  {
    icon: NewYorkIcon,
    title: "New York City, NY",
  },
  {
    icon: LosAngelesIcon,
    title: "Los Angeles, CA",
  },
  {
    icon: ChicagoIcon,
    title: "Chicago, IL",
  },
  {
    icon: DallasIcon,
    title: "Dallas, TX",
  },
  {
    icon: MiamiIcon,
    title: "Miami, FL",
  },
];
export const subscribePageUrl = "/subscribe";
export const howItWorksPageUrl = "/howitworks";
export const accountPageUrl = process.env.NEXT_PUBLIC_API_URL + "/account/";
export const cartPageUrl = "/cart";
export const productPrice = 29.99;

export const GTM_CONTAINER_ID = "GTM-K2SQTT5";
export const SITE_URL = "https://www.simpleblooms.com";
export const BLOOMSYBOX_SITE_URL = "https://www.bloomsybox.com";
