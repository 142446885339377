import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import type { ReactNode } from "react";
import { styletron, StyletronProvider } from "@/app/styles/styletron";
import { BaseWebThemeProvider, theme } from "@/app/styles/theme";
import { ToasterContainer } from "baseui/toast";
import { GTM_CONTAINER_ID } from "@/shared/constants";
import Script from "next/script";

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 20 } },
});

export const AppProviders = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <>
      {process.env.NODE_ENV === "production" && (
        <Script id="gtm" strategy="afterInteractive">
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');`}
        </Script>
      )}
      <StyletronProvider value={styletron}>
        <BaseWebThemeProvider theme={theme} >
          <ToasterContainer usePortal placement="bottom" autoHideDuration={3000}>
            <QueryClientProvider client={queryClient}>
              {children}
              <ReactQueryDevtools />
            </QueryClientProvider>
          </ToasterContainer>
        </BaseWebThemeProvider>
      </StyletronProvider>
    </>
  );
};
